import React from 'react'
import { SolBg, SolContainer, VideoBg,SolContent, SolH1, SolP, SolBtnWrapper,YellowFont,WhiteFont,SolContent2 } from './Solumina'
import Video from '../../videos/video.mp4'
import { useRef } from 'react'

const Solumina = () => {
  const videoRef= useRef();
  const setPlayBack = () => {
    videoRef.current.playbackRate = 0.7;
  };
  return (
    <SolContainer>
        <SolBg>
            <VideoBg  ref={videoRef}
              onCanPlay={() => setPlayBack()} 
              playsInline autoPlay loop muted style={{ WebkitMediaControls: 'display(none)'}} src={Video} type='video/mp4'/> 
        </SolBg>
        <SolContent>
        <SolP>
          Bringing <YellowFont>joy</YellowFont> to your software story.
        </SolP>
        <SolH1><YellowFont>Chara</YellowFont> Software LLC</SolH1>
        </SolContent>
        <SolContent2>Use our proprietary AI machine learning solution to automate any business process in any industry seamlessly. We offer projects and services to fit any style, time and material, fixed-price, maintenance programs, cloud-based SaaS. As well as Implementation Configuration and Integration services for all Industries. THE WORLD IS COUNTING ON US TO INNOVATE & COLLABORATE</SolContent2>
    </SolContainer>
  )
}

export default Solumina