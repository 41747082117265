//Most important place to update data that is displayed on website.
//TopLine is the name of that tab we're on. 
//Headline is the title can be catchy etc. 
//If we add tabs, scroll make sure to alternate colors if its one after another

import { SideTitle } from "./AI";

export const aiObjOne = {
    id: 'AIOne',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'ARTIFICIAL INTELLIGENCE',
    headline: 'Unifying & Simplifying Business Operations Strategically',
    description: 'Chara will deliver you to experience the future of automation through AI integration', 
    buttonLabel: 'Recieve A Demo Now',
    dark: true,
    primary: true,
    darkText: true, 
    addImg: true, 
    sideTitle1: 'Accessible Across All Industries and Processes',
    sideDesc1: 'Whether in finance, health care, retail, or manufacturing our AI-Driven solutions are customized to propel sector-specific growth and efficiency.',
    sideTitle2: 'Confidently Becoming Mechanized',
    sideDesc2: 'Chara\'s AI tool cuts costs, prevents errors, and accerlerates any internal business process.',
    sideTitle3: 'Utilizing the Powerhouse',
    sideDesc3: 'Our neural networks trained with massive data and powered by TensorFlow, PyTorch, Keras are compatiable with AWS, Azure, and Google Cloud.',
    buttonDesc: 'Receive A Demo Now'
  };
  

  