// this component is the three panels for Tips and Tricks 
//Title of Tips is servicesH2
// Descriptions pf tips are in servicesP

import React from 'react'

import { BreakDownContainer, BreakDownH1,BreakDownWrapper,BreakDownCard,BreakDownH2,BreakDownH3,BreakDownH4,BreakDownP,YellowFont } from './BreakDown';

const BreakDown = () => {
  return (
    <BreakDownContainer id='aiPageTwo'>
      <BreakDownH1>BREAK DOWN</BreakDownH1>
      <BreakDownH2>Catapulting a Dream Come True with the Power of Chara's AI Automation</BreakDownH2>
      <BreakDownH4>The best digital mastermind to ever exist breaks down into these following tools</BreakDownH4>
      <BreakDownWrapper>
        <BreakDownCard>
          <BreakDownH3>ARTIFICIAL INTELLIGENCE</BreakDownH3>
          <BreakDownP>
            Imagine AI as your brilliant sidekick that analyze data, makes decisions, and improves continuously. It's like having a genious servant who uncovers hidden secrets and trends within vast amounts of data.
          </BreakDownP>
        </BreakDownCard>
        <BreakDownCard>
          <BreakDownH3>LARGE LANGUAGE MODELS</BreakDownH3>
          <BreakDownP>
            LLMs are AI rockstars excelling in natural language understanding, crafting emails, switching languages effortlessly, and conversing like humans. They are language wizards facilitating seamless communication.
          </BreakDownP>
        </BreakDownCard>
        <BreakDownCard>
          <BreakDownH3>MACHINE LEARNING</BreakDownH3>
          <BreakDownP>
            ML gives AI and LLMs a crash course to become extreamly brainy. You feed them info, they learn the ropes, and suddenly they're automatically acing tasks quickly and efficiently without needing a step-by-step guide. 
            They are in constant evolution.
          </BreakDownP>
        </BreakDownCard>
      </BreakDownWrapper>
      <BreakDownH2>Be Boundless in the Pursuit of <YellowFont>AI Evolution</YellowFont></BreakDownH2>
    </BreakDownContainer>
  );
}

export default BreakDown