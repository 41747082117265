import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const FooterContainer = styled.footer`
  background-color: #101522;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const FooterWrap = styled.div`
  padding: 10px 24px;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  margin: 16px;
  text-align: left;
  color: #fff;
  font-size: 18px;

  @media screen and (max-width: 420px) {
    margin-left: 200px;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
color: #fff;
justify-self: start;
text-decoration: none;
font-size: 1.3rem;
display: flex;
align-items: center;
margin-bottom: 16px;
padding-right: 10px;
font-weight: bold;
`;

export const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  margin-bottom: 16px;
  padding: 10px;

  &:hover {
    color: #F0F01D;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom:50px;
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  // margin: 40px auto 0 auto;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const SocialLogo = styled(Link)`
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  font-weight: bold;
  &:hover {
    color: #F0F01D;
    transition: 0.3s ease-out;
  }
`;

export const WebsiteRights = styled.small`
  color: #fff;
  align-items: center;
  font-size: 15px;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #fff;
  font-size: 24px;

  &:hover {
    color: #F0F01D;
    transition: 0.3s ease-out;
  }
`;
export const Mailto = styled.a`
color: #fff;
justify-self: start;
cursor: pointer;
text-decoration: none;
font-size: 1.5rem;
display: flex;
align-items: center;
font-weight: bold;


&:hover {
  color: #F0F01D;
  transition: 0.3s ease-out;
}
`;