import styled from 'styled-components';
import { MdKeyboardArrowRight, MdArrowForward } from 'react-icons/md';

export const SolContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 30px;
  height: 900px;
  position: relative;
  z-index: 1;
  bottom: -80px;
  flex-direction: column;

  :before {
    content: '';
    position: absolute;
    top: 10;
    left: 0;
    right: 0;
    bottom: 0;
    background: -webkit-gradient(
        linear,
        left top,
        left bottom,
        from(rgba(0, 0, 0, 0.2)),
        to(rgba(0, 0, 0, 0.2))
      ),
      -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.2)), to(transparent));
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

export const SolBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const SolContent = styled.div`
  z-index: 4;
  max-width: 1200px;
  position: relative;
  top: 100px;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 5px solid #fff;
`;

export const SolContent2 = styled.div`
  z-index: 4;
  max-width: 800px;
  position: relative;
  top: 100px;
  padding: 30px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 20px;
`;

export const SolH1 = styled.h1`
  font-size: 60px;
  text-align: center;
  color:#fff;
  

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const SolP = styled.p`
  color: #fff;
  font-size: 45px;
  text-align: center;
  max-width: 1200px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
export const YellowFont = styled.text`
color: #e2d81e;
`
export const SolBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
