// this component is the three panels side by side.
// photos are in src={}
//Title of description is servicesH2
// Descriptions are in servicesP

import React from 'react'
import Training from "../../images/training.jpeg"
import Coding from "../../images/coding.jpeg"
import techHandComp from "../../images/techHandComp.jpeg"

import { ServicesContainer, ServicesH1,ServicesWrapper,ServicesCard,ServicesIcon,ServicesH2,ServicesP } from './Software';

const Software = () => {
  return (
    <ServicesContainer id='soft'>
      <ServicesH1>Software Services</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Training} />
          <ServicesH2>Training</ServicesH2>
          <ServicesP>
            We can train your IT staff how to develop for and install and maintain Solumina.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={techHandComp} />
          <ServicesH2>Staff Augmentation</ServicesH2>
          <ServicesP>
            Use our software developers to augment your development teams across a wide range of technologies.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Coding} />
          <ServicesH2>Custom Development</ServicesH2>
          <ServicesP>
            Our development team can deliver turnkey software solutions for your organization. 
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
}

export default Software