// this component is the three panels for Tips and Tricks 
//Title of Tips is servicesH2
// Descriptions pf tips are in servicesP

import React from 'react'

import { AIAssistantContainer, AIAssistantWrapper, TopHalf, BottomHalf, TopLine, Heading, Subtitle, YellowFont, Button, FlexColumn, TopInfoSection, InfoSection } from './AIAssistant';

const AIAssistant = () => {
  return (
    <AIAssistantContainer>
      <AIAssistantWrapper>
        <TopHalf>
          <TopLine>UTILIZE OUR AI ASSISTANT</TopLine>
          <Heading >Outsmart, Outperform, Outpace.</Heading>
          <TopInfoSection>
            <FlexColumn>
              <Subtitle>Chara's AI solutions aim to optimize your business operations, amplify customer satisfaction, and stimulate growth.</Subtitle>
              <Subtitle><YellowFont>Here's how we ensure ongoing advancement:</YellowFont></Subtitle>
            </FlexColumn>
            <Button>Receive A Demo Now</Button>
          </TopInfoSection>
        </TopHalf>
        <BottomHalf>
          <InfoSection>
            <Subtitle><YellowFont>Unleash Efficiency:</YellowFont></Subtitle>
            <Subtitle>Automate repetitive tasks, reduce human error, and free up valuable time for strategic focus.</Subtitle>
          </InfoSection>
          <InfoSection>
            <Subtitle><YellowFont>Unlock Data-Driven Insights:</YellowFont></Subtitle>
            <Subtitle>Gain actionable intelligence from complex datasets to optimize decision-making and identify growth opportunities.</Subtitle>
          </InfoSection>
          <InfoSection>
            <Subtitle><YellowFont>Elevate Customer Experience:</YellowFont></Subtitle>
            <Subtitle>Provide personalized 24/7 support, resolve inssues instantly, and build stronger customer relationships.</Subtitle>
          </InfoSection>
        </BottomHalf>
      </AIAssistantWrapper>
    </AIAssistantContainer>
  );
}

export default AIAssistant