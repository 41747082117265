import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import pdf from '../../pdf/Chara_Technical_Training_Catalog.pdf'

export const InfoContainer = styled.div`
  color: #fff;
  background: ${({ lightBg }) => (lightBg ? '#f9f9f9' : '#000')};


  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;


export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  height: 1000px;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    height: 1100px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? "'col2 col1'" : "'col1 col2'"};
  /* Must use '\' css class \'' */

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
`;

export const TopLine = styled.div`
  color: #000;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 48px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? '#fff' : '#63666A')};

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 500px;
  margin-bottom: 35px;
  font-size: 18px;
  line-height: 24px;
  color: ${({ darkText }) => (darkText ? '#63666A' : '#fff')};
`;

export const DownloadContent = styled.nav`
  max-width: 400px;
  margin-bottom: 35px;
  margin-top: 20px;
  margin-left: 10px;
  font-size: 18px;
  line-height: 24px;
  color: purple;
  cursor: pointer;
`;

export const GetContent = () => {
  fetch('./SoluminaTechnicalTraining-CharaSoftware.pdf').then(response => {
    response.blob().then(blob => {
      const fileUrl = window.URL.createObjectURL(blob);
      let alink = document.createElement('a');
      alink.href = fileUrl;
      alink.download = 'Chara Technical Training Catalog.pdf';
      alink.click();
    })
  })
}

export const BtnWrap = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 500px;
  height: 400px
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0;
  object-fit: fill;
  @media screen and (max-width: 480px) {
    width:200px;
    height:150px;
  }
`;

export const Logo = styled.img`
  width: 800px;
  height: 700px
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0;
  @media screen and (max-width: 480px) {
    width:250px;
    height:200px;
    margin-top: -80px;
    margin-left: 20px;
  }
`;

export const Imgmore = styled.img`
  width: 300px;
  height:300px
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0;
  display: ${({ addImg }) => (addImg ? 'imgURL' : null)};
  @media screen and (max-width: 480px) {
    width:200px;
    height:150px;
    align-self: center;
    margin-left: 50px;
  }
`;


