// Footer is full of empty links we can connect depending on information we want to add. 
//icon is preplaced and can change look of row when we decide what we want to display.
import React from 'react';
import {
  FaLinkedin,
} from 'react-icons/fa';
import { animateScroll as scroll } from 'react-scroll';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  Mailto
} from './Footer';

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  const Email = ({ email, subject = '', body = '', children }) => {
    let params = subject || body ? '?' : '';
    if (subject) params += `subject=${encodeURIComponent(subject)}`;
    if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;
  
    return <Mailto href={`mailto:${email}${params}`}>{children}</Mailto>;
  };
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
             <SocialMediaWrap>
            <SocialLogo to='/' onClick={toggleHome}>Chara</SocialLogo>
            <SocialIcons>
              <SocialIconLink href='https://www.linkedin.com/company/chara-software-llc' target='_blank' aria-label='Linkedin'>
                <FaLinkedin />
              </SocialIconLink>
            </SocialIcons>
            <FooterLinkItems>
              <Email email='ahart@CharaSoftwareLLC.com' target="_blank" rel="noopener noreferrer">Contact Us</Email>
            </FooterLinkItems>
            </SocialMediaWrap>
          </SocialMedia>
            <WebsiteRights>Chara Software LLC © 2024 All rights reserved.</WebsiteRights>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
