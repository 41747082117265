//Most important place to update data that is displayed on website.
//TopLine is the name of that tab we're on. 
//Headline is the title can be catchy etc. 
//If we add tabs, scroll make sure to alternate colors if its one after another

export const homeObjTwo = {
    id: 'Home',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Solumina Division',
    headline: 'Chara is the most experienced Solumina Systems Integrator',
    description:
      'Chara provides Solumina services ranging from technical training to installation support and configuration solutions', 
    downloadDesc: 'Solumina Technical Training Catalog',
    buttonLabel: 'Get Started',
    imgStart: false,
    img: require('../../images/techHand.jpeg'),
    alt: 'techHand',
    dark: true,
    primary: true,
    darkText: true, 
    imgURL: require('../../images/ibaseTbub.png'),
    addImg: true, 
  };

  export const homeObjThree = {
    id: 'company',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Company',
    headline: 'About Us',
    description:
      "Chara is the most experienced Solumina Systems Integrator (SI) and an official partner of iBASEt."+
      "Our Solumina services include technical training (we offer a dozen different courses), installation support, troubleshooting, integration,implementation/configuration, performance tuning."+
      "We can do (and we have done) just about anything when it comes to Solumina."+
      "iBASEt eliminated their offshore implementation team in favor of using us. We have Fortune 100 companies as direct clients."+
      "Our principal engineers have over 20 years of experience with Solumina and have been employees of iBASEt in the past."+
      "We use the state-of-the-art development principles and processes (CI/CD integration, test-driven development, etc)."+
      "We also have dozens of qualified software developers (US citizens) experienced in an array of technologies (Java, Oracle DB, SQL Server, React JS, JavaScript, XML, JSON, SQL, NoSQL DBs, RESTful web services, SOAP web services, etc.) that can be utilized as staff augmentation on any project.",
    imgURL: require('../../images/ibaseTbub.png'),
    buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../images/Chara.png'),
    alt: 'techHand',
    dark: true,
    primary: true,
    darkText: true, 
    addImg: true
  };
  

  