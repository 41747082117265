import React from 'react'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, Row1, Row2, Row3, TextWrapper, TopLine, Heading, Subtitle,ImgWrap,Img, Logo, Imgmore, SideTitle, SideDesc, Button } from './AI';
const AIPage = ({
      lightBg,
      imgStart,
      topLine,
      lightText,
      headline,
      description,
      img,
      alt,
      id,
      primary,
      darkText,
      addImg, 
      imgURL,
      sideTitle1,
      sideDesc1,
      sideTitle2,
      sideDesc2,
      sideTitle3,
      sideDesc3,
      buttonDesc
    }) => {
      console.log(primary);
      return (
        <>
          <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
              <InfoRow imgStart={imgStart}>
                <Column1>
                  <TextWrapper>
                    <TopLine>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headline}</Heading>
                    <Subtitle darkText={darkText}>{description}</Subtitle>
                    <Button>{buttonDesc}</Button>
                  </TextWrapper>
                </Column1>
                <Column2>
                  <Row1>
                    <SideTitle>{sideTitle1}</SideTitle>
                    <sideDesc1>{sideDesc1}</sideDesc1>
                  </Row1>
                  <hr width="100%" side="1"/>
                  <Row2>
                    <SideTitle>{sideTitle2}</SideTitle>
                    <sideDesc2>{sideDesc2}</sideDesc2>
                  </Row2>
                  <hr width="100%" side="1"/>
                  <Row3>
                    <SideTitle>{sideTitle3}</SideTitle>
                    <sideDesc3>{sideDesc3}</sideDesc3>
                  </Row3>
                </Column2>
              </InfoRow>
            </InfoWrapper>
          </InfoContainer>
        </>
      );
    };

export default AIPage