import styled from 'styled-components';

export const BreakDownContainer = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0 100px;
  background:#000;

  @media screen and (max-width: 1000px) {
    height: 1500px;
  }

  @media screen and (max-width: 768px) {
    height: 1300px;
    padding: 0 20px;
  }

  @media screen and (max-width: 480px) {
    height: 1400px;
  }
`;

export const BreakDownWrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 50px;
  padding: 0 50px;
  padding-bottom: 60px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 50px;
    grid-gap: 20px;
    padding-bottom: 40px;
  }
`;

export const BreakDownCard = styled.div`
  background:#e2d81e;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  max-height: 500px;
  padding: 40px;
  max-width: 300px;
`;

export const BreakDownIcon = styled.img`
  height: 200px;
  width: 250px;
  margin-bottom: 10px;
`;

export const BreakDownH1 = styled.h1`
  font-size: 30px;
  color: #e2d81e;
  margin-bottom: 32px;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;

export const BreakDownH4 = styled.h1`
  font-size: 30px;
  color: #e2d81e;
  margin-bottom: 32px;

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
`;


export const BreakDownH2 = styled.h2`
  font-size: 48px;
  color: #fff;
  margin-bottom: 20px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;

export const YellowFont = styled.text`
color: #e2d81e;
`

export const BreakDownH3 = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #000;
  text-align: center;
  font-weight: bold;
`;

export const BreakDownP = styled.p`
  font-size: 1rem;
  text-align: center;
  color: #000;
`;
