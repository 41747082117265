import React from 'react'
import { InfoContainer, InfoWrapper, InfoRow, Column1, Column2, TextWrapper, TopLine, Heading, Subtitle, DownloadContent, GetContent, BtnWrap,ImgWrap,Img, Logo, Imgmore } from './Info';
const Info = ({
      lightBg,
      imgStart,
      topLine,
      lightText,
      headline,
      description,
      downloadDesc,
      buttonLabel,
      img,
      alt,
      id,
      primary,
      darkText,
      dark,
      dark2,
      imgdesc, 
      addImg, 
      imgURL
    }) => {
      console.log(primary);
      return (
        <>
          <InfoContainer lightBg={lightBg} id={id}>
            <InfoWrapper>
              <InfoRow imgStart={imgStart}>
                <Column1>
                  <TextWrapper>
                    <TopLine>{topLine}</TopLine>
                    <Heading lightText={lightText}>{headline}</Heading>
                    <Subtitle darkText={darkText}>{description}</Subtitle>
                    <ImgWrap addImg = {addImg}>
                      <Imgmore src={imgURL} />
                    </ImgWrap>
                    {/* <BtnWrap>
                      <Button
                        to='home'
                        smooth={true}
                        duration={500}
                        spy={true}
                        exact='true'
                        offset={-80}
                        primary={primary ? 1 : 0}
                        dark={dark ? 1 : 0}
                        dark2={dark2 ? 1 : 0}
                      >
                        {buttonLabel}
                      </Button>
                    </BtnWrap> */}
                  </TextWrapper>
                </Column1>
                <Column2>
                  <ImgWrap>
                    <Logo src={img} alt={alt} />
                  </ImgWrap>
                  <DownloadContent darkText={darkText} onClick={GetContent}>{downloadDesc}</DownloadContent>
                </Column2>
              </InfoRow>
            </InfoWrapper>
          </InfoContainer>
        </>
      );
    };

export default Info