import React, {useState, useEffect } from 'react'
import {FaBars} from 'react-icons/fa'
import { MoblieIcon, Nav, NavbarContainer, NavItem, NavLinks, NavLogo, NavMenu, LogoImg } from './Navbar'
import Charalogo from '../../images/Chara.png'
import { animateScroll as scroll } from 'react-scroll';

export const Navbar = ({toggle}) => {
    const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 60) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
        <Nav scrollNav={scrollNav}>
            <NavbarContainer>
                <NavLogo to='/'>
                    <LogoImg src={Charalogo}>
                    </LogoImg>
                </NavLogo>
                <MoblieIcon onClick={toggle}>
                    <FaBars/>
                </MoblieIcon>
                <NavMenu>
                    <NavItem>
                        <NavLinks to="AIOne" smooth={true} duration={500} spy={true} exact='true' offset={-20}>Artificial Intelligence</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="Home" smooth={true} duration={500} spy={true} exact='true' offset={-20}>Solumina Division</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="soft" smooth={true} duration={500} spy={true} exact='true' offset={-20}>Software Services</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="tips" smooth={true} duration={500} spy={true} exact='true' offset={-20}>Tips</NavLinks>
                    </NavItem>
                    <NavItem>
                        <NavLinks to="company" smooth={true} duration={500} spy={true} exact='true' offset={-20}>Company</NavLinks>
                    </NavItem>
                </NavMenu>
            </NavbarContainer>
        </Nav>
    </>
  );
};

export default Navbar;