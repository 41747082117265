import React, {useState} from 'react'
import Info from '../components/InfoSection';
import Navbar from '../components/Navbar'
import Sidebar from '../components/Sidebar';
import Solumina from '../components/Solumina';
import { homeObjTwo, homeObjThree } from '../components/InfoSection/Data';
import Software from '../components/SoftwareServices';
import Footer from '../components/Footer';
import Tips from '../components/Tips';
import AIPage from '../components/AI';
import { aiObjOne } from '../components/AI/AIData';
import BreakDown from '../components/BreakDown';
import AIAssistant from '../components/AIAssistant';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        setIsOpen(!isOpen);
    };

  return (
    <>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
        <Solumina/>
        <AIPage {...aiObjOne} />
        <BreakDown/>
        <AIAssistant/>
        <Info {...homeObjTwo} />
        <Software/>
        <Tips/>
        <Info {...homeObjThree}/>
        <Footer/>
    </>
  );
}

export default Home;