// this component is the three panels for Tips and Tricks 
//Title of Tips is servicesH2
// Descriptions pf tips are in servicesP

import React from 'react'

import { ServicesContainer, ServicesH1,ServicesWrapper,ServicesCard,ServicesIcon,ServicesH2,ServicesP } from './Tips';

const Tips = () => {
  return (
    <ServicesContainer id='tips'>
      <ServicesH1>Tips and Tricks for Solumina Users</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesH2>Leverage CI/CD Pipelines with Solumina</ServicesH2>
          <ServicesP>
          Continuous integration along with continuous development pipelines keep your development teams efficient. 
           Chara Software has years of experience building out custom CI/CD pipelines for Solumina implementations. 
            When your developers aren't concerned with deployment and automated testing capture bugs, your team delivers faster. 
             Contact Chara Software today to learn how we can build your custom pipeline.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesH2>Bypass/Ignore Solumina Error Messages Not Needed for Your Business</ServicesH2>
          <ServicesP>
            The Solumina product is shipped with thousands of business rules with corresponding error messages.
            Not all errors are treated equally and some of these can prohibit your custom manufacturing process from being the best it can be.  
            Chara Software can leverage custom application code and techniques to bypass/ignore these conditions and allow processing to continue without any additional side effects.
            Contact Chara Software today to learn more.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesH2>Identify Performance Problems Before Your Shop is Affected</ServicesH2>
          <ServicesP>
          Electronic dashboards monitoring Solumina application performance are at your fingertips.
        Chara Software can quickly leverage existing utilities within Solumina and present online and interactive reports along with automatic alerts to help your shop floor work efficiently and without interruption by being proactive.
          Contact Chara Software today to learn how. 
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
}

export default Tips