import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const AIAssistantContainer = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 100px;
  background: #000;

  @media screen and (max-width: 1000px) {
    height: 1300px;
  }

  @media screen and (max-width: 768px) {
    height: 1100px;
    padding: 0 20px;
  }

  @media screen and (max-width: 480px) {
    height: 1600px;
  }
`;


export const AIAssistantWrapper = styled.div`
  max-width: 1500px;
  margin: 0 auto;
  display: grid;
  justify-content: center;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  grid-gap: 50px;
  width: 100%;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
`;

export const TopHalf = styled.div`
  width: 80%;
  padding: 40px;
`;

export const BottomHalf = styled.div`
  max-width: 80%;
  padding-bottom: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-self: center;
  justify-self: center;
  grid-gap: 90px;

  @media screen and (max-width: 1000px) {
    
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
`;

export const TopLine = styled.div`
  color: #e2d81e;
  font-size: 20px;
  line-height: 16px;
  font-weight: bold;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 30px;
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 3.5rem;
  font-weight: bold;
  color: #fff;

  @media screen and (max-width: 1000px) {
    
  }

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.p`
  max-width: 500px;
  margin-bottom: 20px;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
`;

export const Img = styled.img`
  width: 500px;
  height: 400px
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0;
  object-fit: fill;
  @media screen and (max-width: 480px) {
    width:200px;
    height:150px;
  }
`;

export const Logo = styled.img`
  width: 800px;
  height: 700px
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0;
  @media screen and (max-width: 480px) {
    width:250px;
    height:200px;
    margin-top: -80px;
    margin-left: 20px;
  }
`;

export const BottomTitle = styled.p`
  max-width: 500px;
  margin-bottom: 15px;
  margin-top: 35px;
  font-size: 18px;
  line-height: 24px;
  color: #e2d81e;
  font-weight: bold;
`;

export const BottomDesc = styled.p`
  max-width: 500px;
  margin-bottom: 35px;
  font-size: 10px;
  line-height: 24px;
  color: #000;
  font-weight: bold;
`;

export const Button = styled.button`
  background-color: #e2d81e;
  border-radius: 35px;
  color: #000;
  text-align: center;
  border: none;
  padding: 20px;
  font-weight: bold;
  font-size: 30px;
  margin-top: 15px;
  width: 75%;
  height: fit-content;
  justify-self: center;
  align-self: center;

  @media screen and (max-width: 768px) {
    font-size: 15px;
    width: 100%;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TopInfoSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;

  @media screen and (max-width: 1000px) {
    
  }

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const YellowFont = styled.text`
  color: #e2d81e;
`

